//Resources
import Types from './Types';
import FormValidationManager from '../../utils/managers/FormValidationManager';
import ContactManager from './ContactManager';
import {getUTMToContact, isDev} from '../../utils/Utils';
import {postContact} from '../../services/api/API';
import {strings} from '../../resources/locales/i18n';
import {addToast} from '../toasts/ToastsActions';
import {ToastConstructor} from '../toasts/ToastsManager';
import {getAsset} from '../../resources/assets/assets';
import VehiclesManager from '../vehicles/VehiclesManager';

export const apiPostContact = (locationSearch, redirectSuccess) => async(dispatch, getState) => {
  const {name, surnames, email, phone, island, concessionaire, model} = getState().ContactReducer;

  await dispatch(setDataContact({prop: 'submit', value: true}));

  // --- 1. Validate form
  const error = await FormValidationManager.formContact(name, surnames, email, phone, island, concessionaire, model);
  await dispatch(setDataContact({prop: 'error', value: error}));
  if (error.length !== 0) {
    return
  }

  // --- 2. Post contact and redirect success
  await dispatch(setDataContact({prop: 'isLoadingContact', value: true}));

  await dispatch(postContact(
    {utm: getUTMToContact(locationSearch)},
    async (tag, response) => {
      if (isDev()) console.log('apiPostContact - Error: ', response);

      let message = strings('error.server400');
      if (response.code === '-1' && response.message && response.message.length !== 0) {
        message = response.message;
      }

      await dispatch(addToast(
        ToastConstructor({
          icon: getAsset('icError'),
          title: strings('toasts.alert.weSorry'),
          delay: 5000,
          body: message,
        })
      ))

      //dispatch(responseError(tag, response));
    },
    async (tag, response) => {
      if (isDev()) console.log('apiPostContact - Success: ', response);
      await dispatch(clearDataContact());

      let message = strings('msgConfirmation.backHomepage')

      if (response.code === '0' && response.message && response.message.length !== 0){
        message = response.message;
      }

      if(redirectSuccess){redirectSuccess(message)}
    }
  ));

  await dispatch(setDataContact({prop: 'isLoadingContact', value: false}));
};

export const validateForm = () => async(dispatch, getState) => {
  const {name, surnames, email, phone, island, concessionaire, politics, model} = getState().ContactReducer;
  const error = await FormValidationManager.formContact(name, surnames, email, phone, island, concessionaire, politics, model);
  await dispatch(setDataContact({prop: 'error', value: error}));
};

export const setDataContact = ({prop, value}) => ({
  type: Types.SET_DATA_CONTACT,
  payload: { prop, value }
});

export const clearDataContact = () => ({
  type: Types.CLEAR_DATA_CONTACT
});

import React from 'react';
import axios from 'axios';
import Config from "react-environment";

// Resources
import Tags from './Tags';
import {isApp, isDev} from '../../utils/Utils';
import {strings} from '../../resources/locales/i18n';
import {setAppState} from '../../modules/app/AppActions';
import VehiclesManager from '../../modules/vehicles/VehiclesManager';


/** VERBS **/
const DEL  = 'DELETE';
const GET  = 'GET';
const POST = 'POST';
const PUT  = 'PUT';


/** *** **/
/** DEL **/
/** *** **/

export const delCartsMineItems = (id, callbackError, callbackSuccess) => async(dispatch, getState) => {
  const { accessToken, storeCode } = getState().UserReducer;

  let store = getStoreCode(storeCode);
  let url    = `rest/${store}/V1/carts/mine/items/${id}`;
  let config = {
    headers: {
      'Authorization': 'Bearer '+ accessToken,
      'Content-Type': 'application/json'
    }
  };
  let params = {};

  return dispatch(launchAsyncTask(Tags.DEL_CART_MINE_ITEMS, DEL, url, config, params, callbackError, callbackSuccess));
};

/** *** **/
/** GET **/
/** *** **/

export const getAppConfig = (props, callbackError, callbackSuccess) => async(dispatch) => {

  let store  = getStoreCode();
  let url    = `/rest/${store}/V1/stockfinder/frontapp/config`;
  let config = {};

  return dispatch(launchAsyncTask(Tags.GET_APP_CONFIG, GET, url, config, null, callbackError, callbackSuccess));
};

export const getDealers = (searchBox, callbackError, callbackSuccess) => async(dispatch) => {

  let store  = getStoreCode();
  let url    = `/rest/${store}/V1/stockfinder/dealers`;
  let config = {};

  return dispatch(launchAsyncTask(Tags.GET_DEALERS, GET, url, config, null, callbackError, callbackSuccess));
};

export const getModelsHome = (props, callbackError, callbackSuccess) => async(dispatch) => {

  let store  = getStoreCode();
  let url    = `/rest/${store}/V1/stockfinder/models/home`;
  let config = {};

  return dispatch(launchAsyncTask(Tags.GET_MODELS_HOME, GET, url, config, null, callbackError, callbackSuccess));
};

export const getVehicle = (id, callbackError, callbackSuccess) => async(dispatch) => {

  let store  = getStoreCode();
  let url    = `/rest/${store}/V1/stockfinder/vehicles/${id}`;
  let config = {};

  return dispatch(launchAsyncTask(Tags.GET_VEHICLE, GET, url, config, null, callbackError, callbackSuccess));
};

export const getVehiclePromotion = (id, callbackError, callbackSuccess) => async(dispatch) => {

  let store  = getStoreCode();
  let url    = `/rest/${store}/V1/stockfinder/vehicles/${id ? id : ''}?promotion=1`;
  let config = {};

  return dispatch(launchAsyncTask(Tags.GET_VEHICLE, GET, url, config, null, callbackError, callbackSuccess));
};

export const getVehicles = (id, callbackError, callbackSuccess) => async(dispatch) => {

  let store  = getStoreCode();
  let url    = `/rest/${store}/V1/stockfinder/vehicles?model=${id ? id : ''}`;
  let config = {};

  return dispatch(launchAsyncTask(Tags.GET_VEHICLES, GET, url, config, null, callbackError, callbackSuccess));
};

export const getVehiclesSearch = (searchBox, callbackError, callbackSuccess) => async(dispatch) => {

  let store  = getStoreCode();
  let url    = `/rest/${store}/V1/stockfinder/search/vehicles?q=${searchBox.trim()}`;
  let config = {};

  return dispatch(launchAsyncTask(Tags.GET_VEHICLES_SEARCH, GET, url, config, null, callbackError, callbackSuccess));
};


/** **** **/
/** POST **/
/** **** **/

export const postContact = (props, callbackError, callbackSuccess) => async(dispatch, getState) => {
  const {name, surnames, email, phone, island, concessionaire, politics, comunicationsAccepted, analysisAccepted, dataProtectAccepted, model} = getState().ContactReducer;

  let store = getStoreCode();
  let url    = `rest/${store}/V1/stockfinder/contact`;

  let config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const utmData = props?.utm ? {...props?.utm} : {}
  let params = {
    contact: {
      firstname: name,
      lastname: surnames,
      email: email,
      telephone: phone,
      dealer_id: concessionaire,
      island_id: island,
      comunications_accepted: comunicationsAccepted,
      analysis_accepted: analysisAccepted,
      data_protect_accepted: dataProtectAccepted,
      model_code: model,
      ...utmData
    }
  };

  return dispatch(launchAsyncTask(Tags.POST_CONTACT, POST, url, config, params, callbackError, callbackSuccess));
};

export const postFinancingSimulator = (props, callbackError, callbackSuccess) => async(dispatch) => {
  const {amount, initialEntry, postponement} = props;

  let store = getStoreCode();
  let url    = `rest/${store}/V1/stockfinder/vehicles/finsimulator`;

  let config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  let params = {
    financeSimulationRequest: {
        amount: amount,
        entry: initialEntry,
        months: postponement,
    }
};

  return dispatch(launchAsyncTask(Tags.POST_FINANCING_SIMULATOR, POST, url, config, params, callbackError, callbackSuccess));
};

export const postReservation = (props, callbackError, callbackSuccess) => async(dispatch, getState) => {
  const {
    concessionaire, email, island, 
    name, rgpd, surnames, phone, 
    comunicationsAccepted, analysisAccepted, 
    dataProtectAccepted, postponement,
    initialEntry, resultFinancing,
  } = getState().VehiclesReducer;

  let store = getStoreCode();
  let url    = `rest/${store}/V1/stockfinder/contact`;
  let config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const vehicleId = props?.vehicleId || ''
  const utmData = props?.utm ? {...props?.utm} : {}
  let params = {
    contact: {
      firstname: name,
      lastname: surnames,
      email: email,
      telephone: phone,
      dealer_id: concessionaire,
      island_id: island,
      terms_accepted: rgpd,
      comunications_accepted: comunicationsAccepted,
      analysis_accepted: analysisAccepted,
      data_protect_accepted: dataProtectAccepted,
      product_id: vehicleId,
      financing_months: postponement,
      financing_quota: VehiclesManager.getFinancingQuotaPrice(resultFinancing)?.quota,
      financing_entry: initialEntry,
      financing_price: VehiclesManager.getFinancingQuotaPrice(resultFinancing)?.price,
      ...utmData
    }
  };

  return dispatch(launchAsyncTask(Tags.POST_CONTACT, POST, url, config, params, callbackError, callbackSuccess));
};

/** *** **/
/** PUT **/
/** *** **/
export const putCartMineCollectTotals = ( callbackError, callbackSuccess) => async(dispatch, getState) => {
  const { accessToken, storeCode } = getState().UserReducer;
  let store  = getStoreCode(storeCode);
  let url    = `rest/${store}/V1/carts/mine/collect-totals`;
  let config = {
    headers: {
      'Authorization': 'Bearer '+ accessToken,
      'Content-Type': 'application/json'
    }
  };

  let params = {
    paymentMethod: {
      method: "checkmo"
    }
  }

  return dispatch(launchAsyncTask(Tags.PUT_CART_COLLECT_TOTALS, PUT, url, config, params, callbackError, callbackSuccess));
};


/** *************** **/
/** PRIVATE METHODS **/
/** *************** **/
export const getStoreCode = (userStoreCode) => {
  const defaultStoreCode = Config.REACT_APP_DEFAULT_STORE_CODE;
  return userStoreCode ? userStoreCode : defaultStoreCode;
};

export const launchAsyncTask = (tag, verb, url, config, params, callbackError, callbackSuccess) => async(dispatch, getState) => {
  let response   = null;
  let httpClient = axios.create();
  httpClient.defaults.baseURL = Config.REACT_APP_BASE_URL;

  /** CONFIG APP **/
  if (isApp()){
    const baseUrl = getState().AppReducer.url;
    if (tag === Tags.GET_URL ){
      httpClient.defaults.baseURL = Config.REACT_APP_BASE_URL;
    }else{
      httpClient.defaults.baseURL = baseUrl;
    }
  }

  if (verb === DEL) {
    await httpClient.delete(url, config)
      .then((result) => { response = result })
      .catch((error) => { response = error.response });
  }

  if (verb === GET) {
    await httpClient.get(url, config)
      .then((result) => { response = result })
      .catch((error) => { response = error.response });
  }

  if (verb === POST) {
    await httpClient.post(url, params, config)
      .then((result) => { response = result })
      .catch((error) => { response = error.response });
  }

  if (verb === PUT) {
    await httpClient.put(url, params, config)
      .then((result) => { response = result })
      .catch((error) => { response = error.response });
  }

  dispatch(onResponse(tag, response, callbackError, callbackSuccess));
};

export const onResponse = (tag, response, callbackError, callbackSuccess) => async(dispatch) => {

  if (isDev()) console.log('TAG: ', tag, ' | Response: ', response);

  if (response === undefined || response === null)
    return;

  switch (response.status) {
    case 200:
      callbackSuccess(tag, response.data);
      break;

    case 400:
      callbackError(tag, response.data);
      break;

    case 401:
      if (isDev()) console.log('Invalid credentials 401 - Logout');

      if (response.data && (tag === Tags.POST_LOGIN || tag === Tags.POST_CUSTOMER_ME_PASSWORD)) {
        return callbackError(tag, response.data);
      }

      //TODO: Logout
      //dispatch(setUserLogout());
      break;

    case 402:
      if (response.data && response.data.error && response.data.error === 'invalidUsername') {
        callbackSuccess(tag, response); // We don't give any clues about the invalid username
      }
      break;

    case 403:
      if (response.data && response.data.error && response.data.error === 'passwordAlreadyRequested') {

      }
      if (response.data && response.data.response && response.data.response.length > 0) {
        // DialogManager.singleAlert(response.data.response);
        callbackError(tag, response);
      }
      break;

    case 404:
      callbackError(tag, response);
      break;

    case 503:
      dispatch(setAppState({prop: 'maintenanceModeEnabled', value: true}))
      break;

    default:
      alert(strings('error.server500'));
      break;
  }
};


import Config from "react-environment";
import {
  addCustomHeadSEO,
} from '../../web/src/resources/styles/js/ScriptsHyundai';
import VehiclesManager from "../modules/vehicles/VehiclesManager";

export const isApp = () => {
  return Config.REACT_APP_IS_APP === 'true'
}

export const isDev = () => {
  return Config.REACT_APP_IS_DEV === 'true'
}

export const isPro = () => {
  return Config.REACT_APP_IS_PRO === 'true'
}

export const isWeb = () => {
  return Config.REACT_APP_IS_WEB === 'true'
}
export const showEnvName = () => {
  if (isDev()) {
    console.log('*************')
    console.log(Config.REACT_APP_NAME)
    console.log('*************')
  }
}

export const formatToPrice = (data) => {
  if (data) {
    const options1 = { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 };
    const numberFormat1 = new Intl.NumberFormat('es-ES', options1);
    return numberFormat1.format(data)
  }
}

export const parseStringVar = (string) => {
  let arr = string.split('_');
  let result = '';

  for (let i = 0; i < arr.length; i++) {
    if (i !== 0) {
      let str = arr[i].toLowerCase();
      arr[i] = str.charAt(0).toUpperCase() + str.slice(1);
    }
    result += arr[i];
  }
  return result;
}

export const parseSelect = (data) => {
  const result = []

  data.map((item) => {
    result.push({ id: item.value_id, value: item.value_label })
  })

  return result;
}

/* LEGALS SCRIPTS */
export const legalScripts_Default = async (props) => {
  const { metasHomeTitle, metasHomeDescription } = props;

  addCustomHeadSEO(
    'default', { metasHomeTitle, metasHomeDescription }
  );
}

export const legalScripts_VehiclesContainer = async (props) => {
  const { metasModelTitle, currentModelDescription, metasModelDescription, allVehiclesMetadesc, allVehiclesMetatitle, idParams, isPromotion, promoVehiclesMetadesc, promoVehiclesMetatitle } = props;

  addCustomHeadSEO(
    'vehicles_container', { metasModelTitle, currentModelDescription, metasModelDescription, allVehiclesMetadesc, allVehiclesMetatitle, idParams, isPromotion, promoVehiclesMetadesc, promoVehiclesMetatitle }
  );
}

export function getVinPixelFacebook(data) {
  let content_ids = [];
  if (data && data.length > 0 && Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      content_ids.push(VehiclesManager.getVin(data[i]));
    }
    return getStringArrayPxFb(content_ids);
  } else if (data && data.vin) {
    content_ids.push(VehiclesManager.getVin(data));
    return getStringArrayPxFb(content_ids);
  }
  return getStringArrayPxFb(content_ids);
}

export function getStringArrayPxFb(array) {
  let string = '[';
  if (array && array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if (i === array.length - 1) {
      string += `'` + array[i] + `']`;
      } else {
        string += `'` + array[i] + `',`;
      }
    }
    return string;
  }
  return string + ']';
}

export function addPixelFacebook(isAppContainer, clickImInterested, content_ids) {
  $(document).ready(function () {
    if (clickImInterested) {
      //search
      let script_addToWishlist = document.createElement('script');
      script_addToWishlist.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '881432012535672');fbq('track', 'AddToWishlist', {content_type: 'vehicle', content_ids: ${content_ids}});`;
      script_addToWishlist.id = 'fbq-addToWishlist';
      //add search
      document.head.appendChild(script_addToWishlist);
    }
    const location = window.location.pathname;
    if (location && !clickImInterested) {

      if (isAppContainer) {
        //pageView
        let script_pageView = document.createElement('script');
        script_pageView.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '881432012535672');fbq('track', 'PageView');`;
        //add pageView
        document.head.appendChild(script_pageView);

        /* noscript */
        let noscript = document.createElement('noscript');
        let img = document.createElement('img');
        img.width = '1';
        img.height = '1';
        img.style = 'display:none';
        img.src = 'https://www.facebook.com/tr?id=881432012535672&ev=PageView&noscript=1';
        noscript.append(img);
        /* add noscript */
        document.head.appendChild(noscript);
      }

      //all scripts
      const allScripts = document.getElementsByTagName('script');
      let numScript = [];
      for (let i = 0; i < allScripts.length; i++) {
        if (
          allScripts[i].id === 'fbq-search' ||
          allScripts[i].id === 'fbq-viewcontent' ||
          allScripts[i].id === 'fbq-lead' ||
          allScripts[i].id === 'fbq-addToWishlist'
        ) {
          numScript.push(i);
        }
      }
      for (let i = 0; i < numScript.length; i++) {
        document.getElementsByTagName('script')[numScript[i]].remove();
      }

      if (location.includes('search=') || location.includes('/vehiculos/')) {
        //search
        let script_search = document.createElement('script');
        script_search.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '881432012535672');fbq('track', 'Search', {content_type: 'vehicle', content_ids: ${content_ids}});`;
        script_search.id = 'fbq-search';
        //add search
        document.head.appendChild(script_search);
      } else if (location.includes('/vehiculo/')) {
        //content (vehicle detail)
        let script_viewContent = document.createElement('script');
        script_viewContent.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '881432012535672');fbq('track', 'ViewContent', {content_type: 'vehicle', content_ids: ${content_ids}});`;
        script_viewContent.id = 'fbq-viewcontent';
        //add viewContent
        document.head.appendChild(script_viewContent);
      } else if (location.includes('/mensaje-confirmacion')) {
        //lead
        let script_lead = document.createElement('script');
        script_lead.innerText = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '881432012535672');fbq('track', 'Lead');`;
        script_lead.id = 'fbq-lead';
        //add lead 
        document.head.appendChild(script_lead);
      }
    }

  })
}

export const EKeyParamsUTM = {
    UTM_MEDIUM: 'utm_medium',
    UTM_SOURCE: 'utm_source',
    UTM_CAMPAIGN: 'utm_campaign',
    UTM_CONTENT: 'utm_content',
    UTM_TERM: 'utm_term',
}

const keyParamsAllow = [
    EKeyParamsUTM.UTM_MEDIUM,
    EKeyParamsUTM.UTM_SOURCE,
    EKeyParamsUTM.UTM_CAMPAIGN,
    EKeyParamsUTM.UTM_CONTENT,
    EKeyParamsUTM.UTM_TERM,
];

export const getUTMToContact = (search) => {
    const obUTM = {}

    try {
        const searchParams = new URLSearchParams(search);
        obUTM.UTM_SOURCE = searchParams.get(EKeyParamsUTM.UTM_SOURCE) || ''
        obUTM.UTM_MEDIUM = searchParams.get(EKeyParamsUTM.UTM_MEDIUM) || ''
        obUTM.UTM_CAMPAIGN = searchParams.get(EKeyParamsUTM.UTM_CAMPAIGN) || ''
    } catch (error) {
        return {
            UTM_SOURCE: '',
            UTM_MEDIUM: '',
            UTM_CAMPAIGN: '',
        }
    }
    return obUTM;
}

export const getQueryParamsUTM = (pathname) => {
  if (pathname) {
    const queryParams = pathname.split('?');
    
    if (queryParams?.length > 1) {
      const params = queryParams[1];
      const arrParams = params.split('&');
      
      if (arrParams?.length > 0) {
        let keyParam = '';
        for (let i = 0; i < arrParams.length; i++) {
          keyParam = arrParams[i].split('=');
          if (keyParam?.length > 0) {
            if (!keyParamsAllow.includes(keyParam[0])) {
              arrParams[i] = '';
            }
          } else arrParams[i] = '';
        }
      }
      
      let queryParamsVerify = '';
      for (let i = 0; i < arrParams.length; i++) {
        if (arrParams[i] && i > 0) {
          queryParamsVerify += '&' + arrParams[i];
        } else if (arrParams[i]) {
          queryParamsVerify += '?' + arrParams[i];
        }
      }
      
      return !queryParamsVerify ? '' : queryParamsVerify;
    }
  }
  
  return '';
}

export const parseQueryParamsUTM = ({pathname, search, history}) => {
  if (pathname && search && history) {
    const queryParams = getQueryParamsUTM(search);
    
    if (queryParams) {
      history.replace(pathname + queryParams);
    }
  }
}
